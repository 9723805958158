import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { setError } from '../../alerts';
import { DateTime } from 'luxon';
import cookies from '../../cookies';

// Components
import { Typography, IconButton } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Api
import { listTests, listVeterinarians, listLabTechs, listAnimalSpecies, listTestTypes, getPractice } from '../../api';

// Icons
import { Visibility, Add } from '@mui/icons-material/';

class Tests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      veterinarians: [],
      labTechs: [],
      animalSpecies: [],
      testTypes: [],
      practice: null,
      searchString: this.props.location?.state?.tableSearchString ?? null
    };
  }

  componentDidMount() {
    // get select options from API
    this.setState({ isLoading: true });

    listVeterinarians({ options: { where: { isActive: { '$not': null } } } }).then(({ results }) => {
      this.setState({ veterinarians: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve veterinarians.');
    });

    listLabTechs({ options: { where: { isActive: { '$not': null } } } }).then(({ results }) => {
      this.setState({ labTechs: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve lab techs.');
    });

    listAnimalSpecies().then((results) => {
      this.setState({ animalSpecies: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve animal species.');
    });

    listTestTypes({ options: { where: { isDemo: false, isComingSoon: false } } }).then(({ results }) => {
      this.setState({ testTypes: results });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve test types.');
    });

    getPractice().then(practice => {
      this.setState({ practice });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve practice. Please try again.');
    });

    this.setState({ isLoading: false });
  }

  render() {
    const locationState = { backTo: { pathname: { pathname: '/tests', state: { tableSearchString: this.state.searchString } }, description: 'All Tests' } };
    const COLUMNS = [
      {
        title: 'Start Time',
        remoteField: 'timestamp',
        search: 'date',
        value: (rowData) => rowData.getTimestamp()?.toLocaleString({
          ...DateTime.DATETIME_MED,
          timeZoneName: 'short',
          timeZone: this.state.practice?.getAddress()?.getTimeZone() ?? undefined
        }),
        customSort: direction => ['timestamp', direction === 'asc' ? 'desc' : 'asc'] // Flip the direction of sorting
      },
      {
        title: 'View',
        omitFromExport: true,
        render: (rowData) => (
          <>
            <Link to={{ pathname: '/tests/' + rowData.getId(), state: locationState }}><IconButton><Visibility /></IconButton></Link>
          </>
        )
      },
      {
        title: 'Veterinarian',
        remoteField: 'veterinarianId',
        value: rowData => rowData.getVeterinarian()?.getFullName(),
        search: 'select',
        selectOptions: this.state.veterinarians?.map(value => ({ value: value.getId(), label: value.getFullName() })) ?? [],
        disableSort: true,
      },
      {
        title: 'Lab Tech',
        remoteField: 'labTechId',
        value: rowData => rowData.getLabTech()?.getFullName(),
        search: 'select',
        selectOptions: this.state.labTechs?.map(value => ({ value: value.getId(), label: value.getFullName() })) ?? [],
        disableSort: true,
      },
      {
        title: 'Animal',
        remoteField: '$animal.name$',
        value: rowData => rowData.getAnimal()?.getName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Client First Name',
        remoteField: '$animal.owner_first_name$',
        value: rowData => rowData.getAnimal()?.getOwnerFirstName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Client Last Name',
        remoteField: '$animal.owner_last_name$',
        value: rowData => rowData.getAnimal()?.getOwnerLastName(),
        search: 'column',
        disableSort: true,
      },
      {
        title: 'Animal Species',
        remoteField: '$animal.animal_species_id$',
        value: rowData => rowData.getAnimal()?.getAnimalSpecies()?.getName(),
        search: 'select',
        selectOptions: (this.state.animalSpecies.length > 1) ? (this.state.animalSpecies.map(value => ({ value: value.getId(), label: value.getName() }))) : ([]),
        disableSort: true,
      },
      {
        title: 'Test Type',
        remoteField: 'testTypeId',
        value: rowData => rowData.getTestType()?.getTestTypeGroup()?.getName() ?? rowData.getTestType()?.getName(),
        search: 'select',
        selectOptions: (this.state.testTypes.length > 1) ? (this.state.testTypes.map(value => ({ value: value.getId(), label: value.getTestTypeGroup() != null ? `${value.getTestTypeGroup()?.getName()} (${value.displayType === 'EPG' ? 'Clinical' : 'Presence of'})` : value.getName() }))) : ([]),
        disableSort: true,
      },
    ];

    return (
      <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>Tests</Typography>

        <MaterialTable
          title='Tests'
          headerContent={
            cookies.getInDebugMode() || cookies.isInstrumentSet() ? (
              <Button component={Link} to={{ pathname: '/tests/new', state: locationState }}>
                Start New
                <Add style={{ marginLeft: '5px' }} />
              </Button>
            ) : null
          }
          options={{
            pageSize: 100,
            hideExport: cookies.isInstrumentSet() ? true : false,
            flipHorizontalScroll: true
          }}
          data={{
            mode: 'remote',
            columns: COLUMNS,
            fetchRemoteData: ({ page, rowsPerPage, where = {}, order = [] }) =>
              new Promise((resolve, reject) => {
                let options = {
                  offset: page != null && rowsPerPage != null ? page * rowsPerPage : undefined,
                  limit: rowsPerPage,
                  where,
                  order,
                };
                listTests({ options }).then((res) => {
                  resolve(res);
                }).catch((error) => {
                  reject(error);
                });
              })
          }}
          onError={(error) => {
            setError(error);
          }}
          onSearch={search => this.setState({ searchString: JSON.stringify(search) })}
          defaultSearchString={this.props.location?.state?.tableSearchString}
        />

      </div>
    );
  }
}

Tests.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Tests);
