import React from 'react';
import PropTypes from 'prop-types';

// API
import { createVeterinarian, updateVeterinarian } from '../../api';

// Alerts
import { setError, setSuccess } from '../../alerts';

// Lib
import { Veterinarian } from '@parasightsysteminc/companion-react';

// Components
import { Button } from '@lexcelon/react-util';
import { Grid, Autocomplete, TextField } from '@mui/material';

// Constants
import { VETERINARIAN_TITLES } from '@parasightsysteminc/companion-react';

class VeterinarianForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      title: null,
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    // If this is an edit form, pre-fill the form
    if (this.props.veterinarian != null) {
      this.setState({
        firstName: this.props.veterinarian.getFirstName() ?? '',
        lastName: this.props.veterinarian.getLastName() ?? '',
        email: this.props.veterinarian.getEmail() ?? '',
        title: this.props.veterinarian.getTitle() ?? null,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    // Start loading
    this.setState({ isLoading: true });

    const {
      firstName,
      lastName,
      email,
      title
    } = this.state;

    if (this.props.veterinarian != null) {
      let veterinarianChanges = {};
      if (firstName !== this.props.veterinarian.getFirstName()) veterinarianChanges.firstName = firstName;
      if (lastName !== this.props.veterinarian.getLastName()) veterinarianChanges.lastName = lastName;
      if (email !== this.props.veterinarian.getEmail()) veterinarianChanges.email = email;
      if (title !== this.props.veterinarian.getTitle()) veterinarianChanges.title = title;
      updateVeterinarian({ id: this.props.veterinarian.getId(), ...veterinarianChanges }).then((veterinarian) => {
        setSuccess('Successfully updated veterinarian!');
        this.setState({ isLoading: false });
        this.props.onSuccess(veterinarian);
      }).catch((err) => {
        setError(err ?? 'Error: Unable to create contact. Please try again.');
        this.setState({ isLoading: false });
      });
    }
    else {
      createVeterinarian({ firstName, lastName, email, title }).then((veterinarian) => {
        setSuccess('Successfully created veterinarian!');
        this.setState({ isLoading: false });
        this.props.onSuccess(veterinarian);
      }).catch((err) => {
        setError(err ?? 'Error: Unable to create contact. Please try again.');
        this.setState({ isLoading: false });
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Grid container direction='row' align-items='space-between' rowSpacing={0} columnSpacing={1}>
          <Grid item xs={12} sm={6}> 
            <TextField
              required
              name='firstName'
              label='First Name'
              value={this.state.firstName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              fullWidth
              style={{ marginBottom: '0.5em' }}
            />
          </Grid>

          <Grid item xs={12} sm={6}> 
            <TextField
              required
              name='lastName'
              label='Last Name'
              value={this.state.lastName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
              fullWidth
              style={{ marginBottom: '0.5em' }}
            />
          </Grid>
        </Grid>
        
        <TextField
          required
          name='email'
          label='Email'
          value={this.state.email}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          fullWidth
          sx={{ mb: '.5em' }}
        />
        <Autocomplete
          disabled={this.state.isLoading}
          clearOnBlur
          fullWidth
          sx={{ mb: '.5em' }}
          value={this.state.title ?? null}
          onChange={(e, value) => this.setState({ title: value })}
          options={VETERINARIAN_TITLES}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={this.state.isLoading}
              required
              variant="filled" label="Vet Title" />
          )}
        />

        {/* Form Buttons */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            marginTop: '40px',
          }}
        >
          {/* Cancel Button - Only shown if in edit mode */}
          {this.props.onClose != null &&
          <Button secondary disabled={this.state.isLoading} style={{ width: '48%' }} onClick={this.props.onClose}>
            Cancel
          </Button>}

          {/* Submit Button */}
          <Button type="submit" isLoading={this.state.isLoading} style={{ width: '48%' }}>
          Submit
          </Button>
        </div>
      </form>
    );
  }
}

VeterinarianForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  veterinarian: PropTypes.instanceOf(Veterinarian),
  onClose: PropTypes.func
};

export default VeterinarianForm;
