import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cookies from '../cookies';

// Components
import { Header } from '@lexcelon/react-util';

// Images
import { SmallLogo } from '@parasightsysteminc/companion-react';

// Constants
import { GREY } from '@parasightsysteminc/companion-react';
import { connect } from 'react-redux';
import { checkAuthentication, logoutUser } from '../redux/actions/authActions';

const LOGGED_IN_LINKS = ({ showInstrumentLinks, showInstrumentSetup }) => [
  ...(showInstrumentSetup ? [
    {
      slug: '/setup',
      text: 'Setup Instrument'
    },
    null
  ] : []),
  ...(showInstrumentLinks ? [
    {
      slug: '/tests/new',
      text: 'Start New Test'
    },
    null,
    {
      slug: `/instruments/${cookies.getInstrumentSerialNumber()}/status`,
      text: 'Cartridge Status'
    },
    {
      slug: `/instruments/${cookies.getInstrumentSerialNumber()}`,
      text: 'My Instrument'
    }
  ] : []),
  {
    slug: '/tests',
    text: 'Previous Tests',
    // subLinks: [
    //   {
    //     slug: '/',
    //     text: 'All Previous Tests'
    //   },
    //   {
    //     slug: '/summary',
    //     text: 'Summary'
    //   }
    // ]
  },
  ...(!showInstrumentLinks ? [{
    slug: '/instruments',
    text: 'All Instruments'
  }] : []),
  {
    slug: '/treatments',
    text: 'Treatments'
  },
  {
    slug: '/veterinarians',
    text: 'Veterinarians'
  },
  {
    slug: '/lab-techs',
    text: 'Lab Techs'
  },
  {
    slug: '/animals',
    text: 'Patients'
  },
  {
    slug: '/account/practice/inventory',
    text: 'Inventory'
  },
  {
    slug: '/account/practice/invoice-counts',
    text: 'Invoice Test Counts'
  },
  {
    slug: '/account',
    text: 'My Account'
  },
  null,
  {
    slug: '/consult',
    text: 'Consult a Parasitologist'
  },
  {
    slug: '/contact-us',
    text: 'Customer Service / Tech Support'
  },
  {
    slug: '/tutorials',
    text: 'Tutorials'
  }
];

const LOGGED_OUT_LINKS = [
  {
    slug: '/auth/login',
    text: 'Login'
  }
];

class AppBar extends Component {
  componentDidMount() {
    this.props.checkAuthentication(this.props.isAuthenticated);
  }

  render() {
    let showInstrumentLinks = cookies.isInstrumentSet() || cookies.getInDebugMode();
    let showInstrumentSetup = cookies.getInDebugMode();
    return (
      <Header
        logo={SmallLogo}
        logoDescription='Parasight Logo'
        links={this.props.isAuthenticated ? LOGGED_IN_LINKS({ showInstrumentLinks, showInstrumentSetup }) : LOGGED_OUT_LINKS}
        backgroundColor={'white'}
        textColor={GREY}
        drawer={this.props.isAuthenticated}
        isLoading={this.props.isLoading}
        onLogout={this.props.isAuthenticated ? this.props.logoutUser : null}
        buttonType='menu'
        hideMenu={location?.pathname?.startsWith('/tests') && location?.pathname?.endsWith('/in-progress')}
        pathname={location?.pathname}
      />
    );
  }
}

AppBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  checkAuthentication: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state?.auth?.isAuthenticated,
  isLoading: state?.auth?.isLoading
});

const mapDispatchToProps = dispatch => ({
  checkAuthentication: (isAuthenticated) => checkAuthentication(dispatch, isAuthenticated),
  logoutUser: () => logoutUser(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppBar));
